import { Config } from "../../../Config";
import { FaceTecSDK } from "../../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import { LivenessCheckProcessor } from "./processors/LivenessCheckProcessor";
import { EnrollmentProcessor } from "./processors/EnrollmentProcessor";
import { AuthenticateProcessor } from "./processors/AuthenticateProcessor";
import { SampleAppUtilities } from "./utilities/SampleAppUtilities";
import { PhotoIDMatchProcessor } from "./processors/PhotoIDMatchProcessor";
import { PhotoIDScanProcessor } from "./processors/PhotoIDScanProcessor";
import { ThemeHelpers } from "./utilities/ThemeHelpers";
import { FaceTecSessionResult, FaceTecIDScanResult } from "../../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { AdditionalScreens } from "./utilities/AdditionalScreens";
import { ClearLatestEnrollmentIdentifier, GetLatestEnrollmentIdentifier, OnComplete } from "./sampleAppControllerReference/SampleAppControllerReference";
import { DeveloperStatusMessages } from "./utilities/DeveloperStatusMessages";

export var SampleApp = ((): any => {
    var latestEnrollmentIdentifier = "";
    var latestProcessor: LivenessCheckProcessor | EnrollmentProcessor | AuthenticateProcessor | PhotoIDMatchProcessor | PhotoIDScanProcessor;
    var latestSessionResult: FaceTecSessionResult | null = null;
    var latestIDScanResult: FaceTecIDScanResult | null = null;
    var localizedLanguage = require("../../../core-sdk-optional/FaceTecStrings.pt-br.js");

    // Wait for onload to be complete before attempting to access the Browser SDK.
    window.onload = (): void => {
        SampleAppUtilities.formatUIForDevice();
      
        // Set a the directory path for other FaceTec Browser SDK Resources.
        FaceTecSDK.setResourceDirectory("../../core-sdk/FaceTecSDK.js/resources");

        // Set the directory path for required FaceTec Browser SDK images.
        FaceTecSDK.setImagesDirectory("../../core-sdk/FaceTec_images");

        // Initialize FaceTec Browser SDK and configure the UI features.
        Config.initializeFromAutogeneratedConfig(FaceTecSDK, function (initializedSuccessfully: boolean) {
            if (initializedSuccessfully) {
                const urlParams = new URLSearchParams(window.location.search);
                const validationtype = urlParams.get('validationtype');
                const id = urlParams.get('id');
                if (validationtype != 'authrequest') {
                    ValidateSession(id);
                }
                FaceTecSDK.configureLocalization(localizedLanguage);
             
                ThemeHelpers.setAppTheme("Pseudo-Fullscreen");
                const viewportHeight = window.innerHeight;
                const viewportWidth = window.innerWidth;
              

                // Calculate the scroll positions for centering
                const scrollTop = ( viewportHeight) / 2;
                const scrollLeft = ( viewportWidth) / 2;

                // Scroll to the calculated center position
                window.scrollTo({
                    top: scrollTop,
                    left: scrollLeft,
                   
                });

                // Block further scrolling
                document.body.style.overflow = 'hidden';

                switch (validationtype) {
                    case 'liveness':
                        onLivenessCheckPressed();
                        break;
                    case '3d2d':
                        onPhotoIDMatchPressed();
                        break;
                    case 'Auth':
                        onEnrollUserPressed();
                        break;
                    case 'authrequest':
                        onAuthenticateUserPressed()
                        break;
                    default:
                        onLivenessCheckPressed();

                }
              
            }
            else {
                window.location.href = "/expiredLink.html";
            }
        });
       
        SampleAppUtilities.fadeInMainUIContainer();
    };



    // Clear previous session results;
    function initializeResultObjects(): void {
        latestSessionResult = null;
        latestIDScanResult = null;
    }

    // Initiate a 3D Liveness Check.
    function onLivenessCheckPressed(): void {
        initializeResultObjects();
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

        // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
        getSessionToken((sessionToken?: string): void => {
            latestProcessor = new LivenessCheckProcessor(sessionToken as string, SampleApp as any);
        });
    }

    // Initiate a 3D Liveness Check, then storing the 3D FaceMap in the Database, also known as "Enrollment".  A random enrollmentIdentifier is generated each time to guarantee uniqueness.
    function onEnrollUserPressed(): void {
        initializeResultObjects();
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

        // Get a Session Token from the FaceTec SDK, then start the Enrollment.
        getSessionToken((sessionToken?: string) => {
            const urlParams = new URLSearchParams(window.location.search);

            const id = urlParams.get('id');
            latestEnrollmentIdentifier = "browser_sample_app_" + id;
            latestProcessor = new EnrollmentProcessor(sessionToken as string, SampleApp as any);
        });
    }

    // Perform 3D to 3D Authentication against the Enrollment previously performed.
    function onAuthenticateUserPressed(): void {
        initializeResultObjects();
        const urlParams = new URLSearchParams(window.location.search);

        const id = urlParams.get('id');
        latestEnrollmentIdentifier = "browser_sample_app_" + id;
        // For demonstration purposes, verify that we have an enrollmentIdentifier to Authenticate against.
        if (latestEnrollmentIdentifier.length === 0) {
            DeveloperStatusMessages.logAndDisplayMessage("Please enroll first before trying authentication.");
        }
        else {
            SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

            // Get a Session Token from the FaceTec SDK, then start the 3D to 3D Matching.
            getSessionToken((sessionToken?: string): void => {
                latestProcessor = new AuthenticateProcessor(sessionToken as string, SampleApp as any);
            });
        }
    }

    // Perform a 3D Liveness Check, then an ID Scan, then Match the 3D FaceMap to the ID Scan.
    function onPhotoIDMatchPressed(): void {
        initializeResultObjects();
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

        // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
        getSessionToken((sessionToken?: string): void => {
            latestEnrollmentIdentifier = "browser_sample_app_" + SampleAppUtilities.generateUUId();
            latestProcessor = new PhotoIDMatchProcessor(sessionToken as string, SampleApp as any);
        });
    }

    // Perform Photo ID Scan, generating a username each time to guarantee uniqueness.
    function onPhotoIDScanPressed(): void {
        initializeResultObjects();
        SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

        // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
        getSessionToken(function (sessionToken?: string) {
            latestProcessor = new PhotoIDScanProcessor(sessionToken as string, SampleApp as any);
        });
    }

    // Show the final result with the Session Review Screen.
    var onComplete: OnComplete;

    onComplete = (sessionResult: FaceTecSessionResult | null, idScanResult: FaceTecIDScanResult | null, latestNetworkResponseStatus: number): void => {
        latestSessionResult = sessionResult;
        latestIDScanResult = idScanResult;

        if (latestProcessor.isSuccess()) {
            // Display message to user, Success messages are already logged from the processors.
            DeveloperStatusMessages.displayMessage("See logs for details");
        }
        else {
            // Log result for unSuccess Sessions.
            DeveloperStatusMessages.logScanOncompleteResult(sessionResult, idScanResult);

            // Check for server offline
            if (isNetworkResponseServerIsOffline(latestNetworkResponseStatus) === true) {
                showAdditionalScreensServerIsDown();
                return;
            }
        }

        SampleAppUtilities.showMainUI();
    };

    // Check for server down status
    function isNetworkResponseServerIsOffline(networkResponseStatus: number): boolean {
        return (networkResponseStatus >= 500);
    }

    // Set a new customization for FaceTec Browser SDK.
    function onDesignShowcasePressed(): void {
        ThemeHelpers.showNewTheme();
    }

    function onVocalGuidanceSettingsButtonPressed(): void {
        SampleAppUtilities.setVocalGuidanceMode();
    }

    // Display audit trail images captured from user's last FaceTec Browser SDK Session (if available).
    function onViewAuditTrailPressed(): void {
        SampleAppUtilities.showAuditTrailImages(latestSessionResult, latestIDScanResult);
    }

    let sessionTokenErrorHasBeenHandled = false;

    function ValidateSession(id : any) {
        var XHR = new XMLHttpRequest();
        
        if(!id){
            window.location.href = "/expiredLink.html";
        }   
        console.log(id);
        XHR.open("GET", "https://api.cadrex.tech/liveness" + "/ValidateLivenessID?id=" + id );
        XHR.onreadystatechange = function (): void {
            if (this.readyState === XMLHttpRequest.DONE) {
                console.log(this.responseText);
                if(this.responseText != ""){
                    Config.CallbackUrl = this.responseText;
                }
                else {
                    window.location.href = "/expiredLink.html";
                }
            }
        };
        XHR.onerror = function (): void {
            console.log('expired link');
            XHR.abort();

            window.location.href = "/expiredLink.html";
        };
        XHR.send();
    }

    function onSessionTokenError(xhrStatus: number | undefined): void {
        if (sessionTokenErrorHasBeenHandled === false) {
            sessionTokenErrorHasBeenHandled = true;

            if (xhrStatus !== undefined && isNetworkResponseServerIsOffline(xhrStatus)) {
                showAdditionalScreensServerIsDown();
            }
            else {
                onServerSessionTokenError();
            }
        }
    }

    // Get the Session Token from the server
    function getSessionToken(sessionTokenCallback: (sessionToken: string) => void): void {
        sessionTokenErrorHasBeenHandled = false;

        try {
            var XHR = new XMLHttpRequest();
            XHR.open("GET", Config.BaseURL + "/session-token");
            XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
            XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString("").replace('dsSfuDH42JNW82MpAbuBdpMwTv2klzEc', 'xxxxxxxxxxx'));
            
            XHR.onreadystatechange = function (): void {
                if (this.readyState === XMLHttpRequest.DONE) {
                    var sessionToken = "";

                    try {
                        // Attempt to get the sessionToken from the response object.
                        sessionToken = JSON.parse(this.responseText).sessionToken;

                        // Something went wrong in parsing the response. Return an error.
                        if (typeof sessionToken !== "string") {
                            onSessionTokenError(XHR.status);
                            return;
                        }
                    }
                    catch {
                        // Something went wrong in parsing the response. Return an error.
                        XHR.abort();
                        onSessionTokenError(XHR.status);
                        return;
                    }

                    SampleAppUtilities.hideLoadingSessionToken();
                    sessionTokenCallback(sessionToken);
                }
            };

            // Wait 3s, if the request is not completed yet, show the session token loading screen
            window.setTimeout(() => {
                if (XHR.readyState !== XMLHttpRequest.DONE) {
                    if (sessionTokenErrorHasBeenHandled === false) {
                        SampleAppUtilities.showLoadingSessionToken();
                    }
                }
            }, 3000);

            XHR.onerror = function (): void {
                XHR.abort();
                onSessionTokenError(XHR.status);
            };

            XHR.send();
        }
        catch (e) {
            onSessionTokenError(undefined);
        }
    }

    function showAdditionalScreensServerIsDown(): void {
        AdditionalScreens.showServerUpGradeView();
    }

    function onServerSessionTokenError(): void {
        SampleAppUtilities.handleErrorGettingServerSessionToken();
    }

    var getLatestEnrollmentIdentifier: GetLatestEnrollmentIdentifier = (): string => {
        return latestEnrollmentIdentifier;
    };

    var clearLatestEnrollmentIdentifier: ClearLatestEnrollmentIdentifier = () => {
        latestEnrollmentIdentifier = "";
    };

    //function exitAdditionalScreen(): void {
    //    AdditionalScreens.exitAdditionalScreen(SampleAppUtilities.showMainUI);
    //}

    return {
        onLivenessCheckPressed,
        onEnrollUserPressed,
        onAuthenticateUserPressed,
        onPhotoIDMatchPressed,
        onPhotoIDScanPressed,
        onDesignShowcasePressed,
        onComplete,
        getLatestEnrollmentIdentifier,
        clearLatestEnrollmentIdentifier,
        onVocalGuidanceSettingsButtonPressed,
        onViewAuditTrailPressed,
        latestSessionResult,
        latestIDScanResult
    };
})();
